<template>
  <div class="container-fluid py-3">
    <div class="row justify-content-center">
      <div class="col-12">
        <h5 class="text-center">{{ $t('coins') }}</h5>
        <hr>
      </div>
      <div class="col-3 col-md-2" v-for="(coin, i) in coins" :key="i">
        <div class="card">
          <img class="card-img-top" src="../../assets/images/money.png" alt="Monedas">
          <div class="card-body text-center">
            <h5 class="py-2 mb-0">{{ coin.quantity }}</h5>
            <h3 class="py-2 mb-0">$ {{ coin.price | money }}</h3>
            <b-button :disabled="!isAuthenticated" variant="primary" block :to="{ name: 'CoinsBuy', params: { lang: lang, console: console, quantity: coin.quantity } }">{{ $t('toBuy') }}</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'console',
      'lang',
      'isAuthenticated'
    ])
  },
  data () {
    return {
      coins: window.coins
    }
  }
}
</script>
